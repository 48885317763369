const  InputComponent = Formio.Components.components.input;

class IBANFieldComponent extends InputComponent {

  static schema(...extend) {
    return InputComponent.schema({
      label: 'IBAN Field',
      type: 'IBANField',
      key: 'ibanfield',
    });
  }
      
  static get builderInfo() {
    return {
      title: 'IBAN Field',
      group: 'basic',
      icon: 'terminal',
      weight: 0,
      documentation: '/userguide/#textfield',
      schema: IBANFieldComponent.schema()
    };
  }

  checkValidity(data, dirty, rowData) {
    //Call the super checkValidity method to perform the default input validation.
    const isValid = super.checkValidity(data, dirty, rowData);

    //Call the customValidation method to perform the custom validation.
    const customValidationResult = this.customValidation();

    //If the custom validation failed, set the error message and return false.
    if (customValidationResult !== true) {
      this.setCustomValidity(customValidationResult);
      return false;
    }

    //If the custom validation passed, return the result of the default input validation.
    return isValid;
  }

  customValidation() {
    const value = this.dataValue;
    // return if value is empty
    if (!value) return true;
    const valid = this.validateIBAN(value);
    if (typeof valid === 'string') {
      return valid;
    } else if (!valid) {
      return 'Please enter a valid IBAN Number';
    }
    return true;
  }

  validateIBAN(input) {
    /**
     * This function takes an IBAN as a parameter and returns true if the format is valid and the IBAN is mathematically valid according to the IBAN specification, and false otherwise.
     * Note that this function only validates the format of the IBAN, not its actual existence or correctness. To fully validate an IBAN, you should also check that it belongs to a valid bank and account number combination.
     */
    let CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
        TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return 'Wrong IBAN length';
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return this.mod97(digits) === 1;
  }

  mod97(string) {
    let checksum = string.slice(0, 2), fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

}

Formio.use({
  components: {
      IBANField: IBANFieldComponent
  }
});
